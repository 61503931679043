
function ColorPalette() {

  return (
    <>
      <img
        className="palette"
        src="./palette.png"
        alt="color palette"
      />
    </>
  )
}

export default ColorPalette
